<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
import { Region } from '@entities/region';

export default {
  name: 'UserHeaderRegion',
  components: {
    AppHeaderDropdown,
  },
  data() {
    return {
      regions: [],
      region: '',
      allowedRegions: Region.getAllowedCityGroups(),
    };
  },
  mounted() {
    this.$root.$on('setRegion', region => this.setRegion(region));
    const groups = this.$store.getters['user/groups'];
    this.regions = this.allowedRegions.filter(({ value }) => groups.includes(value));
    this.region = this.$store.getters['user/region'];

    if (!this.region) this.setRegion(this.regions[0].value);
  },
  methods: {
    setRegion(region) {
      this.$store.dispatch('user/changeRegion', region);
      this.region = this.$store.getters['user/region'];
      location.reload();
    },
    getRegion(region) {
      if (!region) return;
      return this.allowedRegions.find(e => e.value === region).text;
    },
  },
};
</script>

<template>
  <AppHeaderDropdown right>
    <template slot="header">
      {{ $t('shared.navbar.region') }} <strong>{{ getRegion(region) }}</strong>
    </template>
    <template slot="dropdown">
      <b-dropdown-item v-for="item in regions" :key="item.value" :title="item.text" @click="setRegion(item.value)">
        {{ item.text }}
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>
